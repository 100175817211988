import React from 'react';
import { graphql } from 'gatsby';
import { InHouseDayEnroll, Seo } from '../components';
import { useBackendQuery } from '../hooks/useBackendQuery';
import gql from 'graphql-tag';

export const query = graphql`
  query($id: ID!) {
    strapi {
      inhouseDay(id: $id) {
        id
      }
    }
  }
`;

const BACKEND_QUERY = gql`
query {
  inhouseDays {
    id
    strapiId
    name
    finalSignupDate
    company {
      name
    }
  }
}`

const InhouseDayInfoTemplate = ({ pageContext }) => {
  const { data: backendData } = useBackendQuery(BACKEND_QUERY)
  const inhouseDay = (backendData?.inhouseDays ?? []).find((day) => day.strapiId === Number(pageContext.id))
  if (!inhouseDay) return null;
  const title = `Aanmelden voor ${inhouseDay.name}`;
  return (
    <div>
      <Seo title={title} />
      <InHouseDayEnroll
        id={inhouseDay.id}
        title={inhouseDay.name}
        companyName={inhouseDay.company.name}
        finalSignupDate={inhouseDay.finalSignupDate}
      />
    </div>
  );
};

export default InhouseDayInfoTemplate;
